export const mixpanelEventName = {
  OPEN_SCREEN: 'openScreen',
  SHARE: 'share',
  SCREENSHOT: 'screenshot',

  VIEW_ERROR: 'view_error',
  VIEW_COMPONENT: 'view_component',
  CLICK_COMPONENT: 'click_component',
  CLICK_BUTTON: 'click_button',
  CLICK_REMOVE: 'click_remove',

  INPUT_NAME: 'input_name',
  INPUT_ADDRESS: 'input_address',
  INPUT_VALUE: 'input_value',
  CHANGE_ADDRESS: 'change_address',
  OTP_REQUEST: 'otp_request',

  LOGIN: 'login',
  SEARCH: 'search',

  VIEW_PROMOTION: 'view_promotion',
  SELECT_PROMOTION: 'select_promotion',
  PROMO_LIST: 'promo_list',

  VIEW_ITEM_LIST: 'view_item_list',
  SELECT_ITEM: 'select_item',

  FAVORITE: 'favorite',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  ORDER_FAILED: 'order_failed',

  BEGIN_CHECKOUT: 'begin_checkout',
  CHECKOUT_PROGRESS: 'checkout_progress',
  ECOMMERCE_PURCHASE: 'ecommerce_purchase',
  APP_MONITORING: 'app monitoring',
} as const

export const mixpanelEventCategory = {
  HOME_PAGE: 'homepage',
  CATEGORY_PAGE: 'category page',
  CATALOGUE_PAGE: 'catalogue page',
  SEARCH_PAGE: 'search page',
  FAVORITE_PAGE: 'favorite page',
  PRODUCT_PAGE: 'product page',
  ORDER_DETAIL_PAGE: 'order detail page',
  REFERRAL_PAGE: 'referral page',
  PROFILE_PAGE: 'profile page',
  ADDRESS_PAGE: 'address page',
  LOYALTY_PAGE: 'loyalty page',
  REMIND_GIFT_PAGE: 'remind gift page',
  PROMO_KAMI_PAGE: 'promo kamu page',
  ORDER_STATUS_PAGE: 'order status page',
  RECOMMENDATION_PAGE: 'recommendation page',
  PWP_PAGE: 'pwp page',
  PWP_CATEGORY_PAGE: 'pwp category page',
  FLASH_SALE_PAGE: 'flash sale page',

  LOGIN: 'login',
  CART: 'cart',
  PWP: 'pwp',
  PICK_LOCATION: 'pick location',
  ORDER_LIST: 'order list',
  VOUCHER_LIST: 'voucher list',
  TOP_NAVIGATION: 'top navigation',
  SPLASH_SCREEN: 'splash screen',
  ONBOARDING_PAGE: 'onboarding page',
  REFERRAL_MISSION: 'referral mission',
  ASTRO_BALANCE: 'astro balance',
  PIN_SUBMISSION: 'pin submission',
  DYNAMIC_LANDING_PAGE: 'dynamic landing page',
  GLOBAL_HOME: 'global home',
  VOUCHER_LIST_DETAIL: 'voucher list detail',
  SEARCH_ADDRESS: 'search address',
} as const
