const BRANCH_PURCHASE_ORDERS = 'branchPurchaseOrders'

type BranchPurchaseOrderType = {
  userId: number
  eventDataAndCustomData: {
    orderId: string
    [key: string]: unknown
  }

  contentItems: Record<string, unknown>[]
}

export const setBranchPurchaseTempData = (data: BranchPurchaseOrderType[]) => {
  localStorage.setItem(BRANCH_PURCHASE_ORDERS, JSON.stringify(data))
}

export const getBranchPurchaseTempData = (): BranchPurchaseOrderType[] => {
  const data = localStorage.getItem(BRANCH_PURCHASE_ORDERS) as string

  return data ? JSON.parse(data) : []
}

export const removeBranchPurchaseTempData = () => {
  localStorage.removeItem(BRANCH_PURCHASE_ORDERS)
}
