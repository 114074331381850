'use client'

import useRouter from 'hooks/useRouter'
import { usePathname, useSearchParams } from 'next/navigation'

import { resetAllSlices } from 'utils/store'
import useUserStore from 'store/user'
import { logoutUserService } from 'app/login/_utils/services'

import useDeferredQuery from './useDeferredQuery'

export const REDIRECT_PATH_SEARCH_KEY = 'redirectPath'

const useUser = () => {
  const { user, sessionToken } = useUserStore((state) => state)
  const router = useRouter()

  const pathname = usePathname()
  const searchParams = useSearchParams()

  const getLatestLoginRedirectPath = () => {
    const redirectPath = searchParams.get(REDIRECT_PATH_SEARCH_KEY) || ''

    return decodeURIComponent(redirectPath)
  }

  const login = () => {
    const currentFullPath = `${pathname}?${searchParams.toString()}`

    const encodedPath = encodeURIComponent(currentFullPath)

    router.push(`/login?${REDIRECT_PATH_SEARCH_KEY}=${encodedPath}`)
  }

  const { refetch: logout } = useDeferredQuery({
    queryKey: ['logout'],
    queryFn: () =>
      logoutUserService().then(() => {
        resetAllSlices()
        window.location.reload()
        return null // Required return by react-query
      }),
  })

  return {
    user,
    logout,
    login,
    sessionToken,
    getLatestLoginRedirectPath,
  }
}

export default useUser
