'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { IconButton } from '@astronautsid/wpe-astro-ui/components/atoms/IconButton'
import { Back } from '@astronautsid/wpe-icons'

import useTopNav, { TopNavPropsType } from './useTopNav'

export type { TopNavPropsType }

const TopNav = ({ title, onBack, backURL }: TopNavPropsType) => {
  const { handleGoBack, theme } = useTopNav({ backURL })

  return (
    <Box padding="8px" display="flex" alignItems="center" bgcolor={theme.palette.bgColor.light}>
      <Box onClick={onBack || handleGoBack}>
        <IconButton>
          <Back />
        </IconButton>
      </Box>
      {title && (
        <Typography
          fontSize="16px"
          fontWeight="700"
          component="h5"
          flex="1"
          textAlign="center"
          paddingRight="24px"
        >
          {title}
        </Typography>
      )}
    </Box>
  )
}

export default TopNav
