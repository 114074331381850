import useCart from 'hooks/useCart'
import useUserStore from 'store/user'
import useCartStore from 'store/cart/slice'

import {
  populateCartTrackerObject,
  populatePurchaseTracker,
  populateCheckoutTracker,
  populateAddToCartTracker,
} from 'utils/tracking/mixpanel/mixpanelHelper'

const useTrackerHelperHooks = () => {
  const { customerAddress } = useUserStore((state) => state)
  const { formData, shippingCost, voucherData } = useCartStore()
  const { cartData } = useCart()

  const carts = cartData[0]

  const trackObjectOthers = populateCartTrackerObject(customerAddress, formData)
  const purchaseTracker = populatePurchaseTracker(carts, shippingCost, voucherData.voucher_code)
  const checkoutTracker = populateCheckoutTracker(carts, String(customerAddress.customer_id))
  const addToCartTracker = populateAddToCartTracker(carts, String(customerAddress.customer_id))

  return {
    trackObjectOthers,
    purchaseTracker,
    checkoutTracker,
    addToCartTracker,
  }
}

export default useTrackerHelperHooks
