import { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'

import debounce from 'debounce'

import { Typography } from '@astronautsid/wpe-astro-ui/components/atoms/Typography'
import { color } from '@astronautsid/wpe-astro-ui/tokens'
import { Box, type BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'
import { Plus, Minus } from '@astronautsid/wpe-icons'

import useUserStore, { UserType } from 'store/user'
import useCart from 'hooks/useCart'
import useTrackerHelperHooks from 'hooks/useTrackerHelperHooks'
import { trackAddToCart } from 'utils/tracking/branch/eventActions'
import noop from 'utils/function/noop'
import {
  trackClickAddToCart,
  trackClickAddToCartFromPDP,
} from 'utils/tracking/mixpanel/mixpanelEventActions'

type CardIncrementPropsType = {
  productId: number
  propsBox?: BoxPropsType
  maxStock?: number
  showBMSM?: boolean
  source: string
}

const CartIncrement = ({
  productId,
  propsBox,
  source = ``,
  maxStock = 0,
  showBMSM = false,
}: CardIncrementPropsType) => {
  const { cartData, updateToCartFunction } = useCart()

  const { user } = useUserStore((state) => state)
  const { addToCartTracker } = useTrackerHelperHooks()

  const instantCart = cartData.find((item) => item.cart_type.toLowerCase() === 'instant')

  const selectedProduct = instantCart?.items?.find((item) => item.product_id === productId)
  const cartProductQty = useRef(selectedProduct?.quantity || 0)
  const [shownQty, setShownQty] = useState(selectedProduct?.quantity || 0)

  const priceComponents = selectedProduct?.price_components

  const discountPriceProductQty =
    priceComponents?.find((item) => item.component_type === 'DISCOUNT')?.quantity || 0
  const normalPriceProductQty =
    priceComponents?.find((item) => item.component_type === 'NORMAL')?.quantity || 0

  let promoString = ``
  if (discountPriceProductQty > 0 && normalPriceProductQty > 0) {
    promoString = `${discountPriceProductQty} promo + ${normalPriceProductQty} harga normal`
  }

  const handleUpdateCart = async (action: 'add' | 'remove') => {
    await updateToCartFunction({
      cart_type: 'INSTANT',
      product_id: productId,
      quantity: cartProductQty.current,
      is_loyalty_product: false,
      loyalty_location_id: 0,
      pwp_campaign_id: 0,
      total_price_quickmart: 0,
      send_as_gift: false,
      note: null,
      source,
      bottom_sheet_current_page: '',
      modifier_variant_ids: null,
    })

    trackAddToCart({
      user: user as UserType,
      source,
      product: {
        productID: productId,
        productName: selectedProduct?.name,
        productStock: selectedProduct?.stock,
        productPrice: String(selectedProduct?.total_price),
      },
      additionalBUOData: {
        $quantity: cartProductQty.current,
      },
      action,
    })
  }

  const debouncedFunction = useRef(debounce(handleUpdateCart, 500)).current

  const handleMinus = () => {
    const newMinusQty = cartProductQty.current !== 0 ? cartProductQty.current - 1 : 0
    cartProductQty.current = newMinusQty
    setShownQty(newMinusQty)
    debouncedFunction('remove')
  }

  const handlePlus = () => {
    if (maxStock === shownQty) {
      toast.error(`Ups, Kamu sudah mencapai batas pembelian produk ini`, {
        id: 'cart-increment-max-stock',
      })
      return
    }

    if (source === `cart`) {
      trackClickAddToCart({
        ...addToCartTracker,
        cartId: ``,
      })
    } else {
      trackClickAddToCartFromPDP({
        ...addToCartTracker,
        cartId: ``,
      })
    }

    const newQty = cartProductQty.current + 1
    cartProductQty.current = newQty
    setShownQty(newQty)
    debouncedFunction('add')
  }

  return (
    <Box
      border="1px solid"
      borderColor={color.strokeColor.default}
      borderRadius="8px"
      bgcolor={color.bgColor.light}
      display="inline-flex"
      flexDirection="column"
      overflow="hidden"
      {...propsBox}
    >
      <Box display="inline-flex" justifyContent="space-between" padding="4px 8px">
        <Box display="flex" alignItems="center" onClick={shownQty === 0 ? noop : handleMinus}>
          <Minus
            size={16}
            color={shownQty === 0 ? color.iconColor.disable : color.iconColor.default}
            type="solid"
          />
        </Box>

        <Typography variant="body-small" color={color.textColor.primaryDark}>
          {shownQty}
        </Typography>

        <Box display="flex" alignItems="center" onClick={handlePlus}>
          <Plus
            size={16}
            color={maxStock === shownQty ? color.iconColor.disable : color.iconColor.default}
            type="solid"
          />
        </Box>
      </Box>
      {promoString !== `` && showBMSM && (
        <Box
          bgcolor={color.bgColor.darker}
          borderColor={color.strokeColor.default}
          borderRadius="0px 0px 8px 8px"
          padding="4px 18px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Typography variant="caption-tiny" color={color.textColor.primaryLight}>
            {promoString}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default CartIncrement
