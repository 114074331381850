import { CartItemType, CartContentType } from 'utils/apiList/lite/cart'

export const populatePurchaseTracker = (
  carts: CartContentType,
  shipping?: number,
  coupon?: string,
  paymentData?: {
    paymentId: string
    paymentStatus: string
    paymentType: string
  },
) => {
  const cartItems = carts?.items || []

  const trackItems = cartItems.map((item: CartItemType) => ({
    item_name: item.name || ``,
    item_id: item.product_id || ``,
    price: String(item.price_components[0].price) || `0`,
    item_brand: ``,
    item_category: ``,
    item_variant: ``,
    quantity: String(item.quantity) || `0`,
  }))

  return {
    payment_id: paymentData?.paymentId || ``,
    payment_status: paymentData?.paymentStatus || ``,
    payment_type: paymentData?.paymentType || ``,
    revenue: String(carts?.total_price || ``),
    transaction_id: ``,
    affiliation: ``,
    value: String(carts?.total_price || ``),
    tax: ``,
    shipping: String(shipping) || `0`,
    currency: 'IDR',
    coupon: coupon || ``,
    items: trackItems,
    shopId: '',
    shopType: '',
    logisticType: '',
  }
}

export const populateCartTrackerObject = (
  customerAddress: {
    id: number
    location_id: number
  },
  formData: {
    timing_type: string
    order_payment_channel: string
    order_payment_code: string
    order_type: string
  },
) => ({
  userId: String(customerAddress.id),
  locationId: String(customerAddress.location_id),
  deliveryType: formData.timing_type,
  paymentType: formData.order_payment_channel,
  paymentMethod: formData.order_payment_code,
  redeemedCoin: ``,
  containsInstantSuperInCart: formData.order_type === 'INSTANT' ? 'true' : 'false',
})

export const populateCheckoutTracker = (carts: CartContentType, userId: string) => {
  const cartItems = carts?.items || []
  const trackItems = cartItems.map((item: CartItemType) => ({
    item_name: item.name || ``,
    item_id: item.product_id || ``,
    price: String(item.price_components[0].price) || `0`,
    item_brand: ``,
    item_category: ``,
    item_variant: ``,
    quantity: String(item.quantity) || `0`,
  }))

  return {
    userId: String(userId),
    items: trackItems,
    checkout_option: '',
    checkout_step: '',
  }
}

export const populateAddToCartTracker = (carts: CartContentType, userId: string) => {
  const cartItems = carts?.items || []

  const trackItems = cartItems.map((item: CartItemType) => {
    const baseObject = {
      item_name: item.name || ``,
      item_id: item.product_id || ``,
      price: String(item.price_components[0].price) || `0`,
      item_brand: ``,
      item_category: ``,
      item_variant: ``,
      quantity: String(item.quantity) || `0`,
    }

    const inventoryDiscount = item.inventory_discount || { final_price: ``, id: `` }

    const extraObject = {
      item_category5: `${inventoryDiscount.final_price}`,
      item_category4: `${inventoryDiscount.id}`,
      promotion_id: `${inventoryDiscount.id}`,
      promotion_name: typeof inventoryDiscount.id === `number` ? `discount` : ``,
    }

    return {
      ...baseObject,
      ...extraObject,
    }
  })

  return {
    userId,
    items: trackItems,
    item_list: '/cart - product list',
  }
}
