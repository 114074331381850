// Track Commerce Events
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const VIEW_CART = 'VIEW_CART'
export const INITIATE_PURCHASE = 'INITIATE_PURCHASE'
export const ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO'
export const CLICK_AD = 'CLICK_AD'
export const PURCHASE = 'PURCHASE'
export const RESERVE = 'RESERVE'
export const SPEND_CREDITS = 'SPEND_CREDITS'
export const VIEW_AD = 'VIEW_AD'

// Track Content Events
export const SEARCH = 'SEARCH'
export const VIEW_ITEM = 'VIEW_ITEM'
export const VIEW_ITEMS = 'VIEW_ITEMS'
export const RATE = 'RATE'
export const SHARE = 'SHARE'
export const INITIATE_STREAM = 'INITIATE_STREAM'
export const COMPLETE_STREAM = 'COMPLETE_STREAM'

// Track Lifecycle Events
export const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION'
export const COMPLETE_TUTORIAL = 'COMPLETE_TUTORIAL'
export const ACHIEVE_LEVEL = 'ACHIEVE_LEVEL'
export const UNLOCK_ACHIEVEMENT = 'UNLOCK_ACHIEVEMENT'
export const INVITE = 'INVITE'
export const LOGIN = 'LOGIN'
export const START_TRIAL = 'START_TRIAL'
export const SUBSCRIBE = 'SUBSCRIBE'

// Custom Events
export const WEB_PURCHASE = 'WEB_PURCHASE'
export const WEB_FIRST_PURCHASE = 'WEB_FIRST_PURCHASE'
export const FIRST_PURCHASE = 'FIRST_PURCHASE'
export const FIRST_INITIATE_PURCHASE = 'FIRST_INITIATE_PURCHASE'
export const WEB_FIRST_INITIATE_PURCHASE = 'WEB_FIRST_INITIATE_PURCHASE'
