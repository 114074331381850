import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { logger, isClient, defaultStorage, sliceResetFns } from 'utils/store'
import type { SummaryCartResType } from 'utils/apiList/lite/cart'
import { PaymentChannelType } from 'utils/apiList/lite/payment'

type CartSummaryInfoType = {
  subTotal: number
  potentialEarnedCoin: number
  potentialSaveAmount: number
}

export type CartStateType = CartSummaryInfoType & {
  calculateResult: SummaryCartResType
  isLoadingGoods: boolean
  isLoadingShipping: boolean
  shippingCost: number
  platform_fee: number
  currentPayment: PaymentChannelType | null
  formData: {
    final_delivery_fee: number
    hub_id: number
    minimum_duration: number
    order_line_items: {
      order_item_inventory_discount_id: number | null
      order_item_product_id: number
      order_item_product_price: string
      order_item_product_quantity: number
    }[]
    order_note: string
    order_payment_channel: string
    order_payment_channel_id: number
    order_payment_code: string
    order_point_used: number
    order_total_coin: number
    order_total_coin_earned: number
    order_total_discount: number
    order_total_price: string
    order_total_shipping: string
    order_type: string
    order_voucher_id: string
    packaging_fee: number
    payment_fee: number
    send_as_gift: boolean
    service_level_id: number
    shown_gift_ids: number[]
    timeslot_id: number
    timing_id: number
    timing_name: string
    timing_type: string
    [key: string]: unknown
  }
  ccPaymentData: Nullable<{
    payment_channel_code?: string
    midtrans_token?: string
    xendit_token?: string
    midtrans_skip_three_ds_token?: string
    card_number?: string
    payment_gateway?: string
    is_use_pin?: boolean
    is_three_ds?: boolean
    expiry?: string
  }>
  cartMenuTotal: number
  voucherData: {
    voucher_id: number
    voucher_code: string
    benefit_fmt: string
    voucher_type: string
    voucher_amount: number
  }
  isVoucherInvalid: boolean
  voucherInvalidReason: string
  isFarFromCurrentLocation: boolean
}

export type CartActionType = {
  setCalculateResult: (value: SummaryCartResType) => void
  setCartInfo: ({ potentialEarnedCoin, potentialSaveAmount, subTotal }: CartSummaryInfoType) => void
  setShippingCost: (value: CartStateType['shippingCost']) => void
  setFormData: (objectName: string, payload: unknown) => void
  setPlatformFee: (payload: CartStateType['platform_fee']) => void
  setCCPaymentData: (payload: Partial<CartStateType['ccPaymentData']>) => void
  setCartMenuTotal: (payload: CartStateType['cartMenuTotal']) => void
  setVoucherData: (payload: CartStateType['voucherData']) => void
  setVoucherInvalid: (payload: CartStateType['isVoucherInvalid']) => void
  setVoucherInvalidReason: (payload: CartStateType['voucherInvalidReason']) => void
  setFarFromCurrentLocation: (payload: CartStateType['isFarFromCurrentLocation']) => void
  clearCartState: () => void
  setCurrentPayment: (newCurrentPayment: Nullable<PaymentChannelType>) => void
}

export const cartInitialState: CartStateType = {
  calculateResult: {
    total_payment: 0,
    total_point_used: 0,
    total_refund_point_used: 0,
    text_saving: '',
    saving: 0,
    voucher_discount: 0,
    voucher_error: null,
    shipping_cost: 0,
    discount_shipping_cost: 0,
    total_shipping_cost: 0,
    text_discount_shipping: '',
    redeem_point_used: 0,
    is_slash_price: false,
    is_force_use_ovo_point: null,
    total_coins_earned: 0,
    shipping_cost_before_voucher: 0,
    voucher_discounts: [],
  },
  subTotal: 0,
  isLoadingGoods: true,
  isLoadingShipping: true,
  platform_fee: 0,
  potentialEarnedCoin: 0,
  potentialSaveAmount: 0,
  shippingCost: 0,
  currentPayment: null,
  formData: {
    final_delivery_fee: 0,
    hub_id: 0,
    minimum_duration: 0,
    order_line_items: [],
    order_note: '',
    order_payment_channel: '',
    order_payment_channel_id: 0,
    order_payment_code: '',
    order_point_used: 0,
    order_total_coin: 0,
    order_total_coin_earned: 0,
    order_total_discount: 0,
    order_total_price: '',
    order_total_shipping: '',
    order_type: 'INSTANT',
    order_voucher_id: '',
    packaging_fee: 0,
    payment_fee: 0,
    send_as_gift: false,
    service_level_id: 0,
    shown_gift_ids: [],
    timeslot_id: 0,
    timing_id: 0,
    timing_name: '',
    timing_type: '',
  },
  ccPaymentData: null,
  cartMenuTotal: 0,
  // Selected Voucher
  voucherData: {
    voucher_id: 0,
    voucher_code: '',
    benefit_fmt: '',
    voucher_type: '',
    voucher_amount: 0,
  },
  isVoucherInvalid: false,
  voucherInvalidReason: ``,
  isFarFromCurrentLocation: false,
}

export type CartSliceType = CartStateType & CartActionType

export const SLICE_NAME = 'cartStore'

const getPersistedStoreByKey = (state: CartSliceType) =>
  Object.fromEntries(
    Object.entries(state).filter(
      // Excluded keys
      ([key]) => !['currentPayment'].includes(key),
    ),
  )

const useCartStore = create<CartSliceType>()(
  logger(
    persist(
      immer((set) => {
        sliceResetFns.add(() => set(() => cartInitialState))
        return {
          ...cartInitialState,
          setCalculateResult: (payload: SummaryCartResType) =>
            set((state) => {
              state.calculateResult = payload
            }),
          setCartInfo: (payload: CartSummaryInfoType) =>
            set((state) => {
              state.subTotal = payload.subTotal
              state.potentialEarnedCoin = payload.potentialEarnedCoin
              state.potentialSaveAmount = payload.potentialSaveAmount
              state.isLoadingGoods = false
            }),
          setShippingCost: (payload: CartStateType['shippingCost']) =>
            set((state) => {
              state.shippingCost = payload
              state.isLoadingShipping = false
            }),
          setFormData: (objectName: string, payload: unknown) =>
            set((state) => {
              state.formData[objectName] = payload
            }),
          setPlatformFee: (payload: CartStateType['platform_fee']) =>
            set((state) => {
              state.platform_fee = payload
            }),
          setCCPaymentData: (payload: Partial<CartStateType['ccPaymentData']>) =>
            set((state) => {
              if (payload === null) {
                state.ccPaymentData = null
              } else {
                state.ccPaymentData = {
                  ...state.ccPaymentData,
                  ...payload,
                }
              }
            }),
          setCartMenuTotal: (payload: CartStateType['cartMenuTotal']) =>
            set((state) => {
              state.cartMenuTotal = payload
            }),
          setVoucherData: (payload: CartStateType['voucherData']) =>
            set((state) => {
              state.voucherData = payload
            }),
          setVoucherInvalid: (payload: CartStateType['isVoucherInvalid']) =>
            set((state) => {
              state.isVoucherInvalid = payload
            }),
          setVoucherInvalidReason: (payload: CartStateType['voucherInvalidReason']) =>
            set((state) => {
              state.voucherInvalidReason = payload
            }),
          setFarFromCurrentLocation: (payload: CartStateType['isFarFromCurrentLocation']) =>
            set((state) => {
              state.isFarFromCurrentLocation = payload
            }),
          clearCartState: () => set(() => cartInitialState),
          setCurrentPayment: (newCurrentPayment) =>
            set((state) => {
              state.currentPayment = newCurrentPayment
            }),
        }
      }),
      {
        name: SLICE_NAME,
        storage: isClient()
          ? createJSONStorage(() => localStorage)
          : createJSONStorage(() => defaultStorage),
        partialize: (state) => getPersistedStoreByKey(state),
      },
    ),
    SLICE_NAME,
  ),
)

export default useCartStore
