import { usePathname, useRouter as useNextRouter } from 'next/navigation'
import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import NProgress from 'nprogress'

const useRouter = () => {
  const router = useNextRouter()
  const pathname = usePathname()

  const push = (href: string, options?: NavigateOptions) => {
    const targetUrl = new URL(href, window.location.href)
    const currentUrl = new URL(window.location.href)

    const hasSearchParams =
      targetUrl?.searchParams?.toString() !== currentUrl?.searchParams?.toString()
    const paramsChanged = hasSearchParams && targetUrl?.search !== currentUrl?.search

    if (targetUrl.pathname === pathname && !paramsChanged) return Promise.resolve(true)

    NProgress.start()

    return router.push(href, options)
  }

  const replace = (href: string, options?: NavigateOptions) => {
    const targetUrl = new URL(href, window.location.href)

    if (targetUrl.pathname === pathname) return Promise.resolve(true)

    NProgress.start()

    return router.replace(href, options)
  }

  const back = () => {
    NProgress.start()

    return router.back()
  }

  return { ...router, push, replace, back }
}

export default useRouter
