const currencyFormat = (
  price: number,
  withPrefixCurrency = true,
  options: Intl.NumberFormatOptions = {},
) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: withPrefixCurrency ? 'currency' : undefined,
    currency: withPrefixCurrency ? 'IDR' : undefined,
    minimumFractionDigits: 0,
    ...options,
  })
  return formatter.format(price).replace(/\s/g, '') /* $2,500.00 */
}

export default currencyFormat
