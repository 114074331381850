import { logEvent } from 'lib/branch'
import {
  LOGIN,
  COMPLETE_REGISTRATION,
  VIEW_ITEM,
  SUBSCRIBE,
  INITIATE_PURCHASE,
  PURCHASE,
  WEB_PURCHASE,
  WEB_FIRST_PURCHASE,
  FIRST_PURCHASE,
  WEB_FIRST_INITIATE_PURCHASE,
  FIRST_INITIATE_PURCHASE,
  ADD_TO_CART,
} from 'utils/tracking/branch/eventNames'

import { ProductType } from 'app/(product)/utils/services/product'
import { type ProductCardDataType } from 'components/ProductCard/types'
import { UserType } from 'store/user'
import { CartContentType } from 'utils/apiList/lite/cart'

import { getBUOProductData, getOGMetaAddToCart, trackEventToMixpanel } from './helper'
import { setBranchPurchaseTempData, getBranchPurchaseTempData } from './serviceLocalStorage'

/**
 * Branch Event Action List https://docs.google.com/spreadsheets/d/1VvswDcQXlgQdq7Q-DAomLK5cQpGOGTs60y9fRHS6yYE/edit?gid=240597717#gid=240597717
 */

type EventDataAndCustomDataType = Record<string, unknown>

type UserLoginDataType = EventDataAndCustomDataType & {
  phoneNumber: string
  fullName: string
  userId: string
}

export const trackUserLogin = (data: UserLoginDataType) => {
  const customData = { locationId: undefined, ...data }

  logEvent(LOGIN, customData)
  trackEventToMixpanel(LOGIN, customData)
}

export const trackUserCompleteRegistration = (isAddressExist: boolean, isNewBuyer: boolean) => {
  if (!isAddressExist && isNewBuyer) {
    logEvent(COMPLETE_REGISTRATION)
    trackEventToMixpanel(COMPLETE_REGISTRATION)
  }
}

export const trackUserSubscribe = (isAddressExist: boolean, isNewBuyer: boolean) => {
  if (!isAddressExist && isNewBuyer) {
    logEvent(SUBSCRIBE)
    trackEventToMixpanel(SUBSCRIBE)
  }
}

export const trackViewItemPDP = (product: ProductType) => {
  const EventDataAndCustomData = {
    productId: product.product_id,
    productStock: product.product_stock,
    productPrice: product.product_price,
    productName: product.product_name,
  }

  const contentItems = [
    {
      ...getBUOProductData({
        $product_name: product.product_name,
        $price: Number(product.product_price),
        $og_title: 'View Item',
        $og_description: 'Open product detail page',
      }),
    },
  ]

  logEvent(VIEW_ITEM, EventDataAndCustomData, contentItems)
  trackEventToMixpanel(VIEW_ITEM, EventDataAndCustomData)
}

export const trackInitiatePurchase = ({
  user,
  orderId,
  cartItems,
  paymentAmount = '0',
  paymentChannel = '',
  paymentMethod = '',
}: {
  user: UserType
  orderId: string
  cartItems: CartContentType['items']
  paymentAmount: string
  paymentChannel: string
  paymentMethod: string
}) => {
  const eventDataAndCustomData = {
    isFraud: user?.is_fraud || false,
    isNewBuyer: user?.is_new_buyer || false,
    orderId,
    productId: '',
    isSuper: false,
    paymentAmount,
    paymentChannel,
    paymentMethod,
  }

  const contentItems = cartItems.map((i) =>
    getBUOProductData({
      $product_name: i.name,
      $price: Number(`${i.price_components[0].price || 0}`),
      $og_title: 'Purchase',
      $canonical_identifier: 'buy/afterCheckout',
      $og_description: 'Purchase after tap button Bayar on cart screen',
      $quantity: i.quantity,
      $publicly_indexable: false,
    }),
  )

  // Log Events
  logEvent(INITIATE_PURCHASE, eventDataAndCustomData, contentItems)
  trackEventToMixpanel(INITIATE_PURCHASE, eventDataAndCustomData)

  if (user?.is_new_buyer) {
    logEvent(FIRST_INITIATE_PURCHASE, eventDataAndCustomData, contentItems)
    logEvent(WEB_FIRST_INITIATE_PURCHASE, eventDataAndCustomData, contentItems)

    trackEventToMixpanel(FIRST_INITIATE_PURCHASE, eventDataAndCustomData)
    trackEventToMixpanel(WEB_FIRST_INITIATE_PURCHASE, eventDataAndCustomData)
  }

  // Persist to storage for tracking purchase payment verified
  const contentPurchaseItems = contentItems.map((i) => ({
    ...i,
    $og_description: 'Purchase after payment is verified',
    $canonical_identifier: 'purchase/paymentVerified',
  }))

  const currentBranchPurchaseData = getBranchPurchaseTempData()

  setBranchPurchaseTempData([
    ...currentBranchPurchaseData,
    { userId: user.id, eventDataAndCustomData, contentItems: contentPurchaseItems },
  ])
}

export const trackPurchase = ({
  eventDataAndCustomData,
  contentItems,
}: {
  eventDataAndCustomData: Record<string, unknown>
  contentItems: Record<string, unknown>[]
}) => {
  logEvent(PURCHASE, eventDataAndCustomData, contentItems)
  logEvent(WEB_PURCHASE, eventDataAndCustomData, contentItems)

  trackEventToMixpanel(PURCHASE, eventDataAndCustomData)
  trackEventToMixpanel(WEB_PURCHASE, eventDataAndCustomData)
}

export const trackFirstPurchase = ({
  isNewBuyer,
  eventDataAndCustomData,
  contentItems,
  callback,
}: {
  isNewBuyer: boolean
  eventDataAndCustomData: Record<string, unknown>
  contentItems: Record<string, unknown>[]
  callback?: () => void
}) => {
  if (!isNewBuyer) return

  logEvent(FIRST_PURCHASE, eventDataAndCustomData, contentItems)
  logEvent(WEB_FIRST_PURCHASE, eventDataAndCustomData, contentItems)

  trackEventToMixpanel(FIRST_PURCHASE, eventDataAndCustomData)
  trackEventToMixpanel(WEB_FIRST_PURCHASE, eventDataAndCustomData)

  if (callback) callback()
}

export const trackAddToCart = ({
  user,
  product,
  source,
  additionalEventData = {},
  additionalBUOData = {},
  action = 'add',
}: {
  user: UserType
  product: Pick<ProductCardDataType, 'productID' | 'productName' | 'productStock' | 'productPrice'>
  source: string
  additionalEventData?: { searchKeyword?: string; [key: string]: unknown }
  additionalBUOData?: { [key: string]: unknown }
  action?: 'add' | 'remove'
}) => {
  const { searchKeyword, ...restAdditionalEventData } = additionalEventData

  const eventDataAndCustomData = {
    isFraud: user?.is_fraud || false,
    isNewBuyer: user?.is_new_buyer || false,
    searchKeyword,
    productId: product.productID,
    productStock: product.productName,
    productName: product.productStock,
    isSuper: false,
    ...restAdditionalEventData,
  }

  const contentItems = [
    {
      ...getBUOProductData({
        ...getOGMetaAddToCart({ source, action }),
        $product_name: product.productName,
        $price: Number(product.productPrice),
        ...additionalBUOData,
      }),
    },
  ]

  logEvent(ADD_TO_CART, eventDataAndCustomData, contentItems)
  trackEventToMixpanel(ADD_TO_CART, eventDataAndCustomData)
}
