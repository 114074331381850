import { useQuery, type UseQueryOptions } from '@tanstack/react-query'

export type UseDeferredQueryType<T = unknown> = UseQueryOptions<T>

const useDeferredQuery = <TData>(opts: UseDeferredQueryType<TData>) =>
  useQuery({
    ...opts,
    enabled: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    retry: false,
  })

export default useDeferredQuery
