import { type UserType } from 'store/user'
import { type CartContentType } from 'utils/apiList/lite/cart'
import { type SessionDataType } from 'lib/session/config'
import { type PaymentChannelType } from 'utils/apiList/lite/payment'
import { ProductCardDataType } from 'components/ProductCard/types'

import { sendGTMEvent } from 'lib/googleTagManager'
import { FIRST_INITIATE_PURCHASE, INITIATE_PURCHASE, ADD_TO_CART, VIEW_ITEM } from './eventNames'

export const gtmTrackInitiatePurchase = ({
  user,
  customerAddress,
  orderId,
  cartItems,
  currentPayment,
  totalRevenue,
}: {
  user: UserType
  customerAddress: SessionDataType['customerAddress']
  orderId: number
  cartItems: CartContentType['items']
  currentPayment: PaymentChannelType
  totalRevenue: number
}) => {
  const eventName = user.is_new_buyer ? FIRST_INITIATE_PURCHASE : INITIATE_PURCHASE

  const eventData = {
    event: eventName,
    currency: 'IDR',
    products: cartItems.map((i) => ({
      id: String(i.product_id),
      price: Number(`${i.price_components[0].price || 0}`),
      quantity: i.quantity,
    })),
    orderId,
    revenue: totalRevenue,
    userId: user.id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isReachable_instant: true,
    isNewBuyer: !!user.is_new_buyer,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isReachable_super: false,
    locationIdInstant: customerAddress.location_id,
    locationIdSuper: 0,
    isFraud: user.is_fraud,
    metodePembayaran: currentPayment.payment_channel_name,
  }

  sendGTMEvent(eventData)
}

export const gtmTrackAddToCart = ({
  user,
  customerAddress,
  product,
}: {
  user: UserType | null
  customerAddress: SessionDataType['customerAddress']
  product: Pick<ProductCardDataType, 'productID' | 'productPrice'>
}) => {
  const eventData = {
    event: ADD_TO_CART,
    currency: 'IDR',
    products: [
      {
        id: String(product.productID),
        price: Number(product.productPrice),
        quantity: 1,
      },
    ],
    userId: user?.id || 0,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isReachable_instant: true,
    isNewBuyer: !!user?.is_new_buyer,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isReachable_super: false,
    locationIdInstant: customerAddress.location_id,
    locationIdSuper: 0,
    isFraud: user?.is_fraud,
  }

  sendGTMEvent(eventData)
}

export const gtmTrackViewItemPDP = ({
  user,
  customerAddress,
  product,
}: {
  user: UserType | null
  customerAddress: SessionDataType['customerAddress']
  product: Pick<ProductCardDataType, 'productID' | 'productPrice'>
}) => {
  const eventData = {
    event: VIEW_ITEM,
    currency: 'IDR',
    products: [
      {
        id: String(product.productID),
        price: Number(product.productPrice),
        quantity: 1,
      },
    ],
    userId: user?.id || 0,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isReachable_instant: true,
    isNewBuyer: !!user?.is_new_buyer,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isReachable_super: false,
    locationIdInstant: customerAddress.location_id,
    locationIdSuper: 0,
    isFraud: user?.is_fraud,
  }

  sendGTMEvent(eventData)
}
