import useRouter from 'hooks/useRouter'
import { useTheme } from '@astronautsid/wpe-astro-ui/theme'

export interface TopNavPropsType {
  title?: string
  onBack?: () => void
  backURL?: string
}

function useTopNav({ backURL }: TopNavPropsType) {
  const router = useRouter()

  const theme = useTheme()

  const handleGoBack = () => {
    if (backURL) {
      router.replace(backURL)

      return
    }

    router.back()
  }

  return { handleGoBack, theme }
}

export default useTopNav
